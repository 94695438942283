.user-details .profile-circle {
  background-color: transparent;
}

.page-header,
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 15px !important;
  box-shadow: 0px 0 30px 0 rgba(1, 41, 112, 0.2) !important;
}

.app-content .page-header {
  margin-top: 0px !important;
}
.react-bootstrap-table-pagination {
  margin-top: 5px;
}
.react-bootstrap-table-pagination > div {
  margin-bottom: 20px;
}
.pagination.react-bootstrap-table-page-btns-ul {
  max-width: 100%;
  display: block;
}
.pagination.react-bootstrap-table-page-btns-ul li {
  display: inline-block;
}

.app-content .page-header .page-title {
  line-height: 1 !important;
}

.patient-info-row {
  margin-top: 15px;
  padding-top: 5px;
  border-top: 1px solid #e2e6e8;
}
.patient-info-row h6 {
  font-weight: 600;
}
.card-disposition-head {
  width: 100%;
  font-weight: 600;
  margin-top: 20px;
}
.card-disposition {
  width: 100%;
}

.card-actions {
  width: 100%;
}

.card-actions h6 {
  font-weight: 600;
  margin-top: 20px;
}

@media (max-width: 991px) {
  .login-form {
    display: block;
  }
  .left-bx {
    width: 100%;
  }
  .right-bx {
    /* width: 100%; */
    display: none;
  }
}

@media (max-width: 767px) {
  .left-bx {
    padding: 80px 30px;
  }
  .patient-info-row,
  .patient-info-row h6 {
    font-size: 12px;
  }
  .card-disposition,
  .card-disposition-head,
  .card-actions,
  .card-actions h6 {
    font-size: 12px;
  }
  .card-disposition .col-md-3,
  .card-disposition-head .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .card-disposition .col-md-2,
  .card-disposition-head .col-md-2 {
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .card-disposition .col-md-5,
  .card-disposition-head .col-md-5 {
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
}

@media (max-width: 575px) {
  .container {
    max-width: 94%;
  }
  .patient-view .card-header {
    display: inline-block;
  }
}

@media (max-width: 480px) {
  .app-content .employer-profile,
  .app-content .analytics,
  .app-content .side-app {
    padding: 15px 30px !important;
    margin-top: 0px;
  }
  .user-details {
    display: block;
  }
  .profile-circle {
    margin-bottom: 20px;
  }
  .org-info {
    display: block;
  }
  .org-info-title {
    justify-content: flex-start;
    gap: 5px;
  }
}
