body {
  background-color: #fbfcfd !important;
}

/* Organization dashboard style */
.dashboard,
.employer-profile,
.analytics,
.side-app {
  width: 100%;
  max-width: 100%;
  padding: 15px 30px !important;
  margin-top: 80px;
}

.dashboard .card,
.employer-profile .card,
.analytics .card {
  margin-bottom: 30px;
  border: none;
  border-radius: 15px !important;
  box-shadow: 0px 0 30px 0 rgba(1, 41, 112, 0.2) !important;
}

.card.fixed-card-400,
.dept-card {
  height: 400px;
  max-height: 400px;
}

.dashboard .info-card {
  width: auto;
}

.card.info-card.wel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.wel .card-body {
  padding-top: 20px;
}

.dashboard .card-body strong {
  padding-top: 15px;
  font-size: 22px;
  color: #091344;
  font-weight: bold;
}

.card.info-card.wel img {
  max-width: 100%;
}

.dashboard-inner-box strong span {
  font-size: 14px;
}

.dashboard .card-body.dashboard-innter {
  padding: 0px;
  display: flex;
  gap: 15px;
}

.dashboard-inner-box {
  border-right: 1px solid #ccc;
  padding-right: 0px;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-around;
}

.dashboard-inner-box:last-child {
  border: none;
}

.dashboard-inner-section .card-body {
  padding: 1.5rem 1rem;
}

.dashboard-inner-top {
  width: 100%;
  padding: 0 5px 5px 0px;
  display: flex;
}

.dashboard-inner-top h2 {
  margin-top: 10px;
  margin-bottom: 0px;
  color: #a1a1a1;
  font-size: 14px;
}

.dashboard-inner-top .card-icon {
  border-radius: 20% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.dashboard .filter {
  position: absolute;
  right: 1.5rem;
  top: 15px;
  z-index: 99;
}

.dashboard .filter .icon {
  color: #aab7cf;
  padding-right: 20px;
  padding-bottom: 5px;
  transition: 0.3s;
  font-size: 16px;
}

.dashboard .dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  -webkit-animation-name: dropdown-animate;
  animation-name: dropdown-animate;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 0;
}

.dashboard .filter .dropdown-header {
  padding: 8px px 15px;
  font-size: 15px;
}

.dashboard .filter .dropdown-header h6 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #aab7cf;
  margin-bottom: 0;
  padding: 0;
}

.dashboard .filter .dropdown-item {
  padding: 8px 15px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.dashboard .card-title,
.employer-profile .card-title {
  padding: 0 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0.75rem;
}

.analytics .card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  color: #000000;
  font-family: "Poppins", sans-serif;
}

.tbl-v-scroll {
  max-height: 250px;
  overflow-y: scroll;
  margin-bottom: 20px !important;
}

.tbl-v-scroll td {
  border-style: dashed;
  border-color: #ececec;
}

.tbl-v-scroll .table thead th {
  border-top: 0px solid #e4e6f1 !important;
  text-transform: capitalize;
}

.card-title.card-title-heading {
  display: flex;
  justify-content: space-between;
}

h5.card-title.card-title-heading span {
  color: #fff;
  border-radius: 25%;
}

.dashboard .activity {
  font-size: 14px;
  border-left: 0px solid #eaf2f9;
  margin-left: 0px;
}

.patients-list-box ul {
  padding: 0px;
  border-bottom: 1px solid #ccc;
}

.patients-list-box li {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 25px 0;
  justify-content: flex-start;
  align-items: center;
}

.rounded-circle {
  border-radius: 50% !important;
}

.patients-list-box li img {
  width: 90px;
}

.patient-details {
  margin: 0px;
  padding: 0px;
}

.patient-details h2 {
  font-size: 20px;
  margin: 0px;
  padding: 0px;
}

.patient-details p {
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  text-transform: capitalize;
  color: #a1a1a1;
}

.ms-auto {
  margin-left: auto !important;
}

.patients-list-box a span i {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 2px 6px;
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
}

.card-title.upcoming-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.upcoming-viewall {
  color: #000;
  display: inline-block;
  width: auto;
  text-align: right;
  padding: 0;
  font-weight: normal;
  font-size: 14px;
}

h5.card-title.card-title-heading .upcoming-viewall span {
  color: #000;
}

.card-title.card-title-heading .upcoming-viewall span i {
  border: 1px solid #000;
  border-radius: 50%;
  padding: 1.5px 3.5px;
  display: inline-block;
  font-weight: bold;
  font-size: 10px;
  position: relative;
  top: -1px;
}

.upcomming-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-height: 365px;
  height: 365px;
}

.upcomming-box ul {
  padding: 0px;
  overflow-y: auto;
  max-height: 365px;
  padding-right: 15px;
  height: 365px;
}

.upcomming-box ul li {
  list-style: none;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 10px;
  align-items: center;
}

.upcomming-box ul li > span {
  font-size: 16px;
  text-align: center;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 15px;
  background-color: #e9e6e6;
  background-image: linear-gradient(#ffffff, #fcf5eb);
}

.upcomming-box ul li:nth-child(even) > span {
  background-image: linear-gradient(#ffffff, #ece9f3);
}

.add-new-schedule {
  margin-top: 15px;
}

.add-new-schedule a {
  background: #2e3192;
  color: #fff;
  font-size: 20px;
  display: block;
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  font-weight: bold;
}

.add-new-schedule a:hover {
  color: #fff !important;
}

.icon.dropdown-toggle:after {
  display: none;
}

.select-filter,
.patients-filter,
.patients-by-division-filter {
  color: rgb(17, 104, 235);
  background: #f1f5fe;
  border: 0px;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
}

.viewall {
  font-weight: normal !important;
  font-size: 14px;
  color: #000;
  margin-left: 5px;
  display: inline-block;
  margin-top: 15px;
}

/* Track */
.dashboard ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e4e4e4 !important;
  background: #e4e4e4 !important;
  border-radius: 10px;
}

.dashboard ::-webkit-scrollbar {
  background-color: #1069eb;
}

/* Handle */
.dashboard ::-webkit-scrollbar-thumb {
  background: #1069eb !important;
  border-radius: 10px;
}

/* Handle on hover */
.dashboard ::-webkit-scrollbar-thumb:hover {
  background: #1069eb !important;
}

.app-content .notification-container {
  z-index: 999;
}

.select-filter {
  color: rgb(17, 104, 235) !important;
  width: 95px;
  max-width: 100%;
  margin: 0px;
  padding: 0px;
}

.select-filter__control {
  background: #f1f5fe !important;
  border: 1px solid #f1f5fe !important;
  border-radius: 5px !important;
}

.select-filter__single-value,
.select-filter__placeholder,
.select-filter__indicator {
  color: rgb(17, 104, 235) !important;
}

.select-filter__indicator-separator {
  display: none !important;
}

.filter-h {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* -------------------- Header ----------------- */
.app-header1 .header-brand {
  // z-index: 999;
  display: block;
  width: 215px;
}

/* .app-header1.employer-header .header-brand-img {
  width: 140px;
} */

.app-header1 .header-brand-img {
  width: 90px;
}

.top-heading {
  font-size: 22px;
  color: #091344;
  font-weight: bold;
  margin: 0px;
  display: flex !important;
  align-items: center !important;
  text-transform: capitalize;
}

.top-heading span {
  font-size: 14px;
  color: #a1a1a1;
  font-weight: 600;
  padding: 16px 0px 10px 10px;
}

.top-heading span.emp-name {
  font-size: 18px;
  color: #333;
}

.emp-header-nav .nav-icon,
.physician-h-nav .nav-icon {
  font-size: 22px;
  color: #012970;
  margin-right: 25px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 100%;
  padding: 10px 12px;
}

.emp-header-nav .nav-item .nav-link .badge,
.physician-h-nav .nav-item .nav-link .badge {
  position: absolute;
  top: -8px;
  right: -18px;
  padding: 0.26rem 0.2rem;
  min-width: 2rem;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 100%;
}

.emp-header-nav .nav-profile,
.physician-h-nav .nav-profile {
  color: #012970;
}

.emp-header-nav .nav-profile img,
.physician-h-nav .nav-profile img {
  max-height: 36px;
}

.emp-header-nav .nav-profile span,
.physician-h-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}

.emp-header-nav .nav-item:hover .dropdown .dropdown-menu,
.physician-h-nav .nav-item:hover .dropdown .dropdown-menu {
  display: none;
}

.emp-header-nav .nav-item:hover .dropdown.show .dropdown-menu,
.physician-h-nav .nav-item:hover .dropdown.show .dropdown-menu {
  display: block;
}

.emp-header-nav .profile,
.physician-h-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 8px !important;
}

.emp-header-nav .dropdown-header,
.physician-h-nav .dropdown-header {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.emp-header-nav .profile .dropdown-header h6,
.physician-h-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.emp-header-nav .profile .dropdown-header span,
.physician-h-nav .profile .dropdown-header span {
  font-size: 14px;
}

.emp-header-nav .profile .dropdown-item,
.physician-h-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.emp-header-nav .messages {
  inset: 8px -15px auto auto !important;
}

.emp-header-nav .messages img {
  margin: 0 20px 0 10px;
  max-height: 40px;
}

.emp-header-nav .messages h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #444444;
}

.emp-header-nav .messages p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.emp-header-nav .messages .dropdown-item {
  display: flex;
}

.emp-header-nav .notifications {
  inset: 8px -15px auto auto !important;
}

.emp-header-nav .notifications .dropdown-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}

.emp-header-nav .notifications .dropdown-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.emp-header-nav .notifications .dropdown-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.emp-header-nav .notifications .dropdown-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.app-header1 .test-env {
  left: 30px;
}

/* -------------------- Sidebar ----------------- */
.page-main .app-sidebar {
  width: 240px;
  transition: all 0.3s;
  padding-left: 20px;
  padding-right: 20px;
  /* box-shadow: 0px 0px 20px 0px rgba(1, 41, 112, 0.2); */
  background-color: #fff;
  border-right: 1px solid #ccc;
  top: 30px;
}

.page-main .app-sidebar .side-menu {
  margin-bottom: 0;
  padding: 0;
}

.page-main .app-sidebar .side-menu li {
  margin-bottom: 5px;
}

.page-main .app-sidebar .side-menu li a {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #4d4d4d;
  background: #fff;
  padding: 5px 10px;
  border-radius: 8px;
}

.page-main .app-sidebar .side-menu li a:hover {
  color: #fff !important;
  background: #0071bc !important;
}

.page-main .app-sidebar .side-menu li a.active {
  color: #fff !important;
  background: #2e3192 !important;
}

.page-main .app-sidebar .side-menu li a.active i,
.page-main .app-sidebar .side-menu li a:hover i {
  color: #fff !important;
}

.page-main .app-sidebar .side-menu__icon {
  background: #fff;
  width: 30px;
  box-shadow: 0px 0 30px 0 rgba(1, 41, 112, 0.1) !important;
  border-radius: 5px;
  color: #898989;
}

.app-sidebar .side-menu li a.active .side-menu__icon,
.app-sidebar .side-menu li a:hover .side-menu__icon {
  background: transparent;
}

/* --------- Employer profile ------------- */
.user-details {
  list-style: none;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin: 0;
  justify-content: flex-start;
  align-items: center;
}

.employer-info h2 {
  color: #0f123f;
}

.employer-info h4 {
  color: #4ca1e1;
}

.org-info {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  padding: 10px 0;
  margin: 10px 0;
}

.org-info-icon {
  color: #929292;
  text-align: center;
  width: 30px;
  font-size: 18px;
}

.org-info-title {
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  grid-gap: 20px;
  gap: 20px;
}

.org-info-value {
  color: #a1a1a1;
  font-weight: 600;
}

.edit-profile-icon {
  position: absolute;
  right: 20px;
  color: #4ca1e1 !important;
}

/* --------- Analytics ------------- */
.download-reports-section {
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  margin-top: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: center;
}

.select-blue {
  color: #0096ff;
  margin-bottom: 10px;
  width: 200px;
  max-width: 100%;
}

.select-blue__control {
  background: #0581ea !important;
  border: 1px solid #0581ea !important;
  border-radius: 8px !important;
}

.select-blue__single-value,
.select-blue__placeholder,
.select-blue__indicator {
  color: #fff !important;
}

.select-blue__indicator-separator {
  display: none !important;
}

.count-boxes .card i {
  background: #fff;
  padding: 10px;
  margin: 0 10px 0 0;
  border-radius: 10px;
  color: #000;
}

.patients-count-box {
  background-color: #e7e1fb !important;
}

.reports-count-box {
  background-color: #fdf3e9 !important;
}

.drug-tests-count-box {
  background-color: #e8faea !important;
}

.orders-count-box {
  background-color: #ecf3fd !important;
}

.patients-count-box i,
.patients-count-box .percent {
  color: #9879ee !important;
}

.reports-count-box i,
.reports-count-box .percent {
  color: #f2c9a2 !important;
}

.drug-tests-count-box i,
.drug-tests-count-box .percent {
  color: #78ebc4 !important;
}

.orders-count-box i,
.orders-count-box .percent {
  color: #6e9cde !important;
}

.count-boxes .card .d-flex {
  justify-content: space-between;
  align-items: center;
}

.analytics .table thead th {
  border-top: 0px solid #e4e6f1 !important;
  font-weight: bold;
}

.count-boxes .card .percent-diff i {
  background-color: transparent !important;
  margin-right: 0px !important;
  padding-right: 5px;
}

.user-details .circle a {
  min-width: 2rem;
  transition: 0.3s color;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1 !important;
  padding: 0.2rem 0.9rem;
}

.count-boxes .placeholder-content {
  margin-top: 20px !important;
}

.count-boxes .placeholder-content_item {
  background-color: transparent !important;
}

.side-menu__icon {
  margin-right: 5px;
}

.welcome-text {
  display: inline-grid;
}

.analytics .row .table {
  overflow: scroll;
}

.nav-link.navsearch-icon {
  display: none !important;
}

.dropdown .nav-profile > .nav-link .circle {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
}

.current-username {
  color: #1168eb;
  text-transform: capitalize;
}

.add-new-event {
  margin-top: 15px;
}

.add-new-event a {
  background: #2e3192;
  color: #fff;
  font-size: 20px;
  display: block;
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  font-weight: bold;
}

.add-new-event a:hover {
  color: #fff !important;
}

/* --------- Responsive ------------- */
@media (max-width: 1460px) {
  .app-header1 .test-env {
    left: 20px;
    font-size: 16px;
  }
}

@media (max-width: 1459px) {
  .analytics .row .table {
    display: block;
  }
}

@media (max-width: 1200px) {
  .top-heading {
    font-size: 18px;
  }
  .top-heading span.emp-name {
    font-size: 14px;
  }
  .top-heading span {
    font-size: 12px;
  }
  .emp-header-nav .nav-icon,
  .physician-h-nav .nav-icon {
    margin-right: 10px;
  }
}

@media (max-width: 1080px) {
  .top-heading {
    display: none !important;
  }
}

@media (min-width: 1160px) {
  .count-boxes .each-count-box.card .d-flex {
    margin-top: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1447px) {
  .each-count-box.card .card-body {
    padding: 0.5rem 0.5rem;
  }
  .count {
    font-size: 22px;
  }
  .count-boxes .each-count-box.card .d-flex {
    display: block !important;
  }
  .count-boxes .each-count-box.card .percent-diff span {
    font-size: 11px;
  }
  .count-boxes .each-count-box.card .percent-diff span.percent {
    font-size: inherit;
  }
}

@media (min-width: 768px) {
  .app-content {
    margin-left: 240px !important;
  }
}

@media (min-width: 991px) {
  .each-count-box.card {
    min-height: 200px;
  }
  .count-boxes,
  .download-reports.card {
    min-height: 430px;
    height: 430px;
  }
}

@media (max-width: 767px) {
  .app .page-main .app-sidebar {
    left: -240px;
  }
}

@media (max-width: 480px) {
  .app-content .dashboard {
    margin-top: 0px !important;
  }
}

@media (max-width: 380px) {
  .dashboard-inner-section > .row .col-6,
  .count-boxes > .row .col-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
