.login-page-bg {
  background-color: #3750eb;
  position: relative;
  min-height: 100vh !important;
}
.login-page-bg:after {
  content: "";
  position: absolute;
  background: #c5d4fd;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
}

.login-form {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 50px;
  overflow: hidden;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 10px 15px rgb(0 0 0 / 18%);
}
.left-bx {
  width: 55%;
  padding: 140px 100px;
  position: relative;
}
.right-bx {
  width: 45%;
  background-color: #edf0f5;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form h3 {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.login-form .form-control {
  background: #edf0f5;
  border-radius: 10px;
  min-height: 50px;
  border: 2px solid transparent;
}
.login-form .form-control:focus {
  border-color: #edf0f5;
}
.login-form label {
  color: #ccc;
}
.color-blue {
  color: #3750eb;
  font-weight: 600;
}
.login-form .btn-primary {
  border-radius: 10px;
  background-color: #3750eb !important;
}
.top-auto {
  position: absolute;
  bottom: -70px;
  left: 0;
  right: 0;
  text-align: center;
}
.slide-content {
  padding: 20px;
  text-align: center;
}
.slide-content h2 {
  font-size: 26px;
  font-weight: 700;
}
.slide-content p {
  font-weight: 700;
  margin: 0;
  color: #000;
  opacity: 0.5;
}

.logo-bottom-text {
  color: #0701ad;
  font-weight: 700;
  margin-top: 20px;
}
