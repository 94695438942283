// Here you can add other styles
.main .container-fluid {
  padding: 0 15px;
}
.displayTableList .card-header {
  display: none;
}
.displayTableList .card-body {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.add-button-div {
  width: 100%;
  margin: 10px auto;
}

// .displayTableList .card-body a {
//     width: 50%;
// }
// .displayTableList .card-body a button, .displayTableList .card-body a + label {
//     margin-bottom: 16px;
// }
.addNewElementClass,
.displayTableList .card-body a + label {
  margin-bottom: 16px;
}
.displayTableList .react-bootstrap-table-pagination,
.displayTableList a.page-link {
  width: 100%;
  align-items: center;
}
.displayTableList ul.pagination {
  margin: 0;
}
.displayTableList span.react-bootstrap-table-pagination-total {
  padding: 16px;
}
.displayTableList button {
  margin: 0 5px;
}
// .displayTableList .card-body header {
//   width: 50%;
//   padding: 0;
//   justify-content: flex-end !important;
// }
// .displayTableList header div {
//   width: 100%;
// }
// .displayTableList .sc-kgoBCf.hFJClG {
//   display: flex;
//   justify-content: flex-start;
// }
// .displayTableList .sc-bxivhb.jIaJrt.rdt_TableHeadRow {
//   background: #e5f8ff;
// }
// .displayTableList .sc-ifAKCX.sc-EHOje.sc-VigVT.ftSrFl {}
// .displayTableList .rdt_TableHeadRow .rdt_TableCol div {
//   font-size: 14px;
//   font-weight: 600;
//   color: #2f353a;
// }
// .displayTableList .sc-jKJlTe.jDHQPG {
//   border:1px solid #cfcfcf;
// }
// .displayTableList button.btn.btn-danger.btn-sm {
//   margin: 5px;
// }
// .displayTableList .rdt_TableRow:nth-child(even) {
//   background: rgba(0, 0, 0, 0.05);
// }
// .displayTableList .rdt_TableFooter {
//     background: #e5f8ff;
// }
// .displayTableList .rdt_TableFooter {
//     font-weight: 600;
// }
// .displayTableList .rdt_TableFooter * {
//     font-weight: 600;
//     color: #2f353a;
// }

.updateStatus {
  cursor: pointer;
}

.invalid-feedback-text {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545 !important;
}
.preview-img {
  height: 100px !important;
}

li.dropdown-item {
  cursor: pointer;
}

button.sizePerPageaTag.btn.btn-secondary {
  background-color: inherit;
  border: none;
}

.dropdown-btn-col-w {
  background-color: #ffffff;
}

.dropdown-p-28 {
  padding: 2px 8px;
}

.pac-container {
  z-index: 9999 !important;
}

ul.city_list {
  padding: 2px;
  z-index: 99;
}

ul.city_list > li {
  padding: 5px;
  list-style: none;
  font-size: 12px;
  border-top: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

ul.city_list > li:last-child {
  border-bottom: 1px solid #dadada;
}
ul.city_list > li > a,
ul.city_list > li > a:hover {
  color: #262727;
  text-decoration: none;
}
.w-150 {
  width: 150px;
}

.filters-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.border-custom {
  border: 1px solid #e4e7ea !important;
}

.input-group-text-custom {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  text-align: center;
  white-space: nowrap;
  background-color: #f0f3f5;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0px none transparent;
}
// Css for time picker
.timepicker-inline {
  display: inline-block;
}

.btn-info,
.btn-info:hover {
  color: #ffffff !important ;
}

.form-check-inline .form-check-input,
input[type="radio"],
input[type="checkbox"] {
  // margin-top: -1px;
  vertical-align: middle;
}

.form-check-input.test-price-checkbox {
  margin-top: -10px;
}

.preview_container {
  left: 45%;
}

.form-group.from_commission .form-check {
  padding: 0;
}

.form-group.from_commission .form-check .commission_label {
  width: 110px;
}

.form-group.from_commission
  .form-check
  .form-check.form-check-inline
  label.form-check-label {
  width: 50px;
}

.form-group.from_commission .form-check .form-group {
  width: 100%;
  margin: 8px 0;
}
.col-rating {
  color: #ffca00;
}
//Css for duty hours
.time_picker_container .react_times_button.time_picker_preview {
  width: 74px;
  font-size: 12px;
  margin-top: -10px;
}
.designshiv .react_times_button.time_picker_preview .preview_container {
  position: relative;
  left: 40px;
}
.designshiv .time_picker_container {
  float: left;
}
.designshiv .col-md-6.timepicker-inline label {
  float: left;
  width: auto !important;
}
.designshiv .react_times_button.time_picker_preview {
  height: 40px;
}
.row.designshiv {
  width: 290px;
  float: left;
  position: absolute;
  right: 0;
  top: 0px;
}
.dutyHourContainer.card-body .form-group {
  position: relative;
  margin-bottom: 25px;
}
.form-group.paymentpage label {
  width: 100%;
  float: left;
}
.form-group.paymentpage .form-check.form-check-inline {
  width: 47%;
  margin: 0 0 10px;
}
.form-group.paymentpage
  .form-check.form-check-inline
  label.ml-3.mr-3.form-check-label {
  margin: 0px !important;
  padding: 0;
}
.preview_container svg {
  width: 25px;
  height: 22px;
  top: 5px !important;
}
.designshiv .react_times_button.time_picker_preview {
  height: 31px;
  margin-top: -5px;
}

.card .card-body.dutyHourContainer .form-group label {
  width: 75px;
  font-size: 12px;
  margin: 2px 2px 0 !important;
}
.designshiv .preview_container {
  position: absolute;
  left: 50%;
  height: 30px;
  line-height: 30px;
  padding-left: 30px;
}
.displayInlineBlock {
  display: inline-block;
}

// css for bubble notification count
.bubbleCount {
  position: absolute;
  border-radius: 100%;
  background-color: #f30301;
  color: #fff;
}

/* Css for certificate status */
.br-lf-success {
  border-left: 6px solid #4dbd74;
}
.br-lf-danger {
  border-left: 6px solid #f63c3a;
}
.br-lf-warning {
  border-left: 6px solid #ffc107;
}

.form-horizontal .preview-img {
  margin: 10px;
}

/* Css for EmployerHeaderProfilePic */
:root {
  --avatar-size: 2.8rem;
  /* change this value anything, e.g., 100px, 10rem, etc. */
}

.circle {
  background-color: #10a5f5;
  border-radius: 50%;
  height: var(--avatar-size);
  //text-align: center;
  width: var(--avatar-size);
  //align-items:center;
}

.baseInitials {
  color: #fff;
  align-items: center;
  font-family: "Courier New", monospace;
  font-size: calc(var(--avatar-size) / 3); /* 50% of parent */
  font-weight: bold;
  line-height: 1;
  position: relative;
  top: calc(var(--avatar-size) / 4); /* 25% of parent */
}

.initials1 {
  left: 1px;
  margin-top: -2px;
  font-size: calc(var(--avatar-size) / 1.8);
}

.initials2 {
  right: 5px;
  margin-top: -1.5px;
  font-size: calc(var(--avatar-size) / 2);
}

.initials3 {
  right: 7px;
  margin-top: 2px;
  font-size: calc(var(--avatar-size) / 2.5);
}

.initials4 {
  right: 9px;
  margin-top: 3px;
  font-size: calc(var(--avatar-size) / 3);
}

.initials5 {
  right: 10px;
  margin-top: 4px;
  font-size: calc(var(--avatar-size) / 3.5);
}

.password-box {
  position: relative;
}

.password-box .eye-register {
  top: 35px;
  right: 20px;
}

.password-box .eye-login {
  top: 50%;
  right: 18px;
  margin: 0;
  font-size: 20px;
  color: #ccc;
}

.datatable-action .btn-sm {
  margin: 2px 5px;
  min-width: 25px;
}

.btn-primary {
  color: #ffffff !important;
  background-color: #0581ea !important;
  border-color: #0581ea !important;
}

.page .btn-primary:hover,
.page .btn-primary:active {
  color: #ffffff !important;
  background-color: #03569c !important;
  border-color: #03569c !important;
}

.btn-warning {
  color: #fff !important;
  background-color: #f57777 !important;
  border-color: #f57777 !important;
}

.page .btn-warning:hover,
.page .btn-warning:active {
  color: #fff !important;
  background-color: #d44f4f !important;
  border-color: #d44f4f !important;
}

.status-new {
  color: #8f0326;
}

.status-scheduled {
  color: yellowgreen;
}

.status-delivered {
  color: #03569c;
}

.mediaicon {
  border: 1px solid #0581ea !important;
  background: #0581ea !important;
}

.page-main .app-sidebar .side-menu li a.hover,
.page-main .app-sidebar .side-menu li a.active,
.app-sidebar .side-menu li a:hover,
.app-sidebar .side-menu li a:active {
  color: #0581ea !important;
}

.breadcrumb-item.active {
  color: #0581ea !important;
}

a:hover {
  color: #0581ea !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #0581ea !important;
}

.card-title {
  font-weight: 600 !important;
}

.BoxCss {
  width: 25%;
  border: 2px solid black;
  padding: 10px;
  margin: 10px;
}

.center-text-inActivelink {
  text-align: center;
  justify-content: center;
  margin: 20px 0 0;
}

.center-text-notFound {
  padding-top: 10%;
  text-align: center;
  justify-content: "center";
}

// insurance card preview css
.document-preview {
  width: 200px;
  position: relative;
  padding: 10px;
  text-align: center;
  display: grid;
  cursor: pointer;
}

.document-preview .fa {
  font-size: 50px;
}

.document-preview .preview-filename {
  font-weight: 500;
}

.image-preview {
  padding: 10px;
  width: 200px;
  cursor: pointer;
  border: 1px solid #000;
  margin-top: 10px;
}

.app-content .btn-secondary {
  background-color: #cc0e74;
  border-color: #cc0e74;
}

.app-content .btn-danger {
  background-color: #cc0e74;
  border-color: #cc0e74;
}

.app-content .btn-danger:not(:disabled):not(.disabled):active,
.app-content .btn-danger:not(:disabled):not(.disabled).active,
.app-content .show > .btn-danger.dropdown-toggle {
  background-color: #790c5a;
  border-color: #790c5a;
}

.app-content .btn-danger:hover {
  background-color: #790c5a;
  border-color: #790c5a;
}

.app-content .text-danger {
  color: #cc0e74 !important;
}

.gmap-citypicker::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #29334c !important;
  opacity: 1 !important; /* Firefox */
}

.gmap-citypicker:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #29334c !important;
}

.gmap-citypicker::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #29334c !important;
}

.upload-result-box {
  background-color: #ececec;
  padding: 1px 5px;
  border: 1px solid #ececec;
  border-radius: 10px;
  cursor: pointer;
  font-size: 13px;
}

.upload-result-box-disabled {
  background-color: #ececec;
  padding: 1px 5px;
  border: 1px solid #ececec;
  border-radius: 10px;
  cursor: not-allowed;
  font-size: 13px;
  opacity: 0.7;
}

.round-button-position {
  display: flex;
  align-items: center;
}

.round-button-position i {
  margin-left: 8px;
  font-style: normal !important;

  &:hover {
    cursor: pointer;
  }
}

.round-button-red {
  $baseColor: #cc0e74;
  $hoverBaseColor: #333;
  $diameter: 1.5rem;
  background: $baseColor;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 -2px 0 3px darken($baseColor, 10%) inset,
    0 5px 5px rgba(darken($baseColor, 40%), 0.17), 0 15px rgba(#fff, 0.25) inset;
  cursor: pointer;
  display: inline-block;
  height: $diameter;
  width: $diameter;

  &:hover {
    background: #17d092;
    box-shadow: 0 -2px 0 3px darken($hoverBaseColor, 10%) inset,
      0 5px 5px rgba(darken($hoverBaseColor, 40%), 0.17),
      0 15px rgba(#fff, 0.32) inset;
  }
}

.round-button-green {
  $baseColor: #17d092;
  $hoverBaseColor: #333;
  $diameter: 1.5rem;
  background: $baseColor;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 -2px 0 3px darken($baseColor, 10%) inset,
    0 5px 5px rgba(darken($baseColor, 40%), 0.17), 0 15px rgba(#fff, 0.25) inset;
  cursor: pointer;
  display: inline-block;
  height: $diameter;
  width: $diameter;

  &:hover {
    background: #cc0e74;
    box-shadow: 0 -2px 0 3px darken($hoverBaseColor, 10%) inset,
      0 5px 5px rgba(darken($hoverBaseColor, 40%), 0.17),
      0 15px rgba(#fff, 0.32) inset;
  }
}

h1.x-large {
  margin: 0 !important;
  color: #0071bc;
}

p.lead {
  color: #333;
  margin-bottom: 2rem !important;
}

.buttons .btn {
  background: #fff !important;
  color: #0071bc !important;
  border: 2px solid #0071bc !important;
  font-family: "Raleway", sans-serif;
}

.buttons a:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 16px 50px;
  font-size: 1.5rem;
  background: #0071bc !important;
  color: #fff !important;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}

i.fa.fa-android {
  font-size: 2rem;
}

.mr-8 {
  margin-right: 8px;
}

.employee-view-search-input {
  align-items: center;
  display: flex;
  width: 160px;
  max-width: 200px;
}

.cap {
  text-transform: capitalize;
}

// Insurance label CSS
.in-sub-heading {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e6f1;
}

.in-row {
  display: grid;
  margin-bottom: 10px;
}

.in-label {
  font-weight: 500;
}

.inline-input {
  display: inline !important;
  width: 200px !important;
}

.text-strong {
  font-weight: 500;
}

.mr-15 {
  margin-right: 15px;
}

.btn {
  margin-bottom: 2px;
}

.landing-page .btn {
  border-radius: 5px !important;
}

.btn.vaccinated-button {
  padding: 0px 4px;
  background-color: orange;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: initial;
  margin: 0 2px;
}

.btn.vaccinated-button:hover {
  color: white;
}

.consent-button-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header .close {
  padding: 0;
  margin: 0;
  font-size: 2.5rem;
  position: absolute;
  right: 10px;
  top: -5px;
}

.signature-pad-wrapper-style {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.signature-canvas {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px 0 30px;
}

.signature-mro {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  display: block;
  float: right;
}

.consent-form-content {
  border: 2px solid #000;
}

.consent-form-content h4 {
  background-color: #2f5496;
  margin-top: 0;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-size: 15px;
  font-weight: bold;
}

.consent-form-content h5,
.consent-form-content p,
.consent-form-content li {
  padding: 0 10px;
}

.consent-form-content h5 {
  font-weight: bold;
  font-size: 14px;
  margin: 10px 0;
}

.consent-form-content p,
.consent-form-content li {
  font-size: 14px;
  margin: 10px 0 10px !important;
}

.page-header .breadcrumb-item a {
  color: #0581ea !important;
}

.page-header .breadcrumb-item.active {
  color: #051441 !important;
}

.question-label {
  white-space: pre-line;
  font-weight: 600 !important;
}

.subtest-section {
  margin-left: 20px;
}

.newline {
  display: block;
}

.test-info {
  color: #4e4a4a;
  font-size: 0.775rem;
}

.customized-section {
  padding: 10px 30px;
  border: 1px dashed #ccc;
  margin: 15px 30px;
  border-radius: 5px;
}

.customized-comtainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 200px;
  width: 100%;
}

.customized-card {
  column-count: 2;
  column-gap: 0;
  flex: 1;
}

.customized-card-item {
  display: block;
  padding: 10px;
  width: 95%;
  -webkit-column-break-inside: avoid;
  /* Chrome, Safari, Opera */
  page-break-inside: avoid;
  /* Firefox */
  break-inside: avoid;
  /* IE 10+ */
}

.custom-subtests-container {
  margin-left: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.test-disabled {
  color: #8a9095;
}

.test-disabled .test-info {
  color: #8a9095;
}

.react-bootstrap-daterangepicker-container {
  display: block !important;
}

.ml-30 {
  margin-left: 30px;
}

.test-label-css {
  display: block;
  margin-bottom: 10px !important;
  margin-left: 20px !important;
}

.test-label-inner {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 40px;
  text-transform: capitalize;
}

.test-cat-area {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 10px 0;
}

.hidden {
  display: none !important;
}

.reset-link {
  margin-left: 10px;
  text-transform: underline;
  color: #20a8d8;
  cursor: pointer;
  float: right;
}

.test-label {
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.table thead th {
  border-top: 1px solid #e4e6f1 !important;
}

.contact-support-label {
  color: red;
}

.contact-support-label a {
  color: red;
}

.hr-line-space {
  margin: 5px 0 10px;
}

.providerObervationInfo li {
  line-height: 20px;
}

.employment-tests-list {
  padding: 5px 0;
}
.employment-tests-list .row {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.employment-tests-list .row .col-4 {
  padding-right: 0px;
  font-size: 12px;
}

.dis-cat-h {
  color: #0581ea;
}

.single-disposition {
  margin: 10px 0;
}

.single-result-row {
  margin-bottom: 10px;
  position: relative;
  padding-bottom: 5px;
}

.single-result-row::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #d2d7da;
  width: 97%;
  transform: translateX(-50%);
  bottom: 0;
  left: 50%;
}

.single-disposition .single-result-row:last-child::after {
  border-bottom: none;
}

.check-option {
  display: block;
  margin-left: 30px;
  margin-bottom: 10px !important;
}

.link {
  cursor: pointer;
}

.mro-label {
  padding: 2px 4px;
  color: orange;
  font-size: 14px;
  font-weight: 500;
  line-height: initial;
  margin: 0 2px;
}

.h3-red {
  color: red;
}
.test-env {
  margin: 0;
  padding: 0;
  position: relative;
  left: calc(50% - 196px);
  display: flex;
  align-items: center;
}
.mro-info-form {
  display: flex;
  border: 1px solid rgb(214, 211, 211);
  border-radius: 3px;
  padding: 20px 10px 10px 10px;
}

.normal-font {
  text-transform: none;
}

.no-profile {
  width: 150px;
  height: 150px;
}

.profile-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #10a5f5;
  border-radius: 50%;
  height: 150px;
  width: 150px;
}

.profile-circle > img {
  width: 100%;
}

.profile-icon {
  width: 25px;
  height: 25px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  opacity: 0.5;
}

.profile-icon:hover {
  opacity: 1;
}

@media (max-width: 991.98px) {
  .test-env {
    font-size: 12px;
    left: 5px;
  }
}

select:disabled {
  opacity: 0;
}

.mro-observation .positive-for {
  margin: 0 10px;
}

.mro-observation .test-heading {
  background-color: #f1f1f1;
  padding: 10px 0;
  color: #2f5496;
  text-align: center;
}

.mro-observation .commu-option {
  margin: 0 20px;
}

.fw-700 {
  font-weight: 700;
}
.btn-ui {
  display: inline-block;
  padding: 0.375rem 1.25rem;
  line-height: 1.84615385;
  letter-spacing: 0.03em;
  font-weight: 600;
  font-size: 1rem;
  cursor: default !important;
}

.hz-nav-internal.nav,
.hz-steps-internal.nav {
  padding: 5px;
}

.hz-nav-internal .nav-link.active {
  color: #2a79ed;
  text-decoration: underline dotted;
  pointer-events: none;
}

.hz-nav-internal .nav-link {
  color: #20a8d8;
  padding: 0.5rem 1rem;
}

.hz-steps-internal .nav-link {
  color: #000;
}

.hz-steps-internal .nav-link.filled {
  color: #20a8d8;
}

.hz-steps-internal .filled {
  cursor: pointer;
}

.hz-steps-internal .current {
  color: #0d6efd;
}

.ml-20 {
  margin-left: 20px;
}

.check-label2 {
  display: block;
  margin-bottom: 10px;
  margin-left: 20px;
  text-transform: capitalize;
}

.add-patient-box {
  padding: 100px;
}

.card-result-header {
  font-weight: 600;
  width: 100%;
  padding: 10px 40px 0 40px;
}

.test-results-list {
  margin: auto 40px;
}

.bg-none {
  background-color: transparent !important;
}

.immgration-form-header {
  background-color: #e0e0e0;
  border: 1px solid rgb(56, 56, 56);
  border-radius: 5px;
}

.immgration-form-sub-header {
  background-color: #e0e0e0;
  font-style: italic;
  padding: 5px;
  width: 100% !important;
  margin-bottom: 10px;
}

.text-large {
  font-size: 18px;
}

.pl-25 {
  padding-left: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.link {
  color: blue;
}

.table-input {
  max-width: 130px !important;
}

.immigration-accordian.accordion .accordion-header {
  border: none;
  padding: unset;
}

.immigration-accordian.accordion .accordion-body {
  padding: 15px 25px 50px;
}

// Bouncing Loader ///////////

.loader-outer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes bouncing-loader {
  to {
    opacity: 0.1;
    -webkit-transform: translate3d(0, -16px, 0);
    transform: translate3d(0, -16px, 0);
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    -webkit-transform: translate3d(0, -16px, 0);
    transform: translate3d(0, -16px, 0);
  }
}

.bouncing-loader {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.bouncing-loader > div {
  width: 13px;
  height: 13px;
  margin: 32px 3px;
  background: #727cf5;
  border-radius: 50%;
  animation-name: bouncing-loader;
  animation-duration: 4s;
  -webkit-animation: bouncing-loader 0.6s infinite alternate;
  animation: 0.6s infinite alternate bouncing-loader;
}

.bouncing-loader > div:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  background: #fa5c7c;
}

.bouncing-loader > div:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  background: #0acf97;
}

///////////////////////////////////
.login-org-list {
  margin-top: 20px;
  border: 1px solid rgb(195, 193, 193);
}

.login-org-list .each-org {
  border-bottom: 1px solid rgb(195, 193, 193);
  padding: 10px 0;
  line-height: 30px;
}

.login-org-list .each-org:last-child {
  border-bottom: none;
}
